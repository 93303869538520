import { ObjectType } from '@/app/constants/typedef';
import { IUser } from './IUser';
import { DateType } from '../date';
import { ImageObjectTypes } from '../avatar/avatar';

class UserModel implements IUser {
    id: string;
    nickname: string;
    email?: string;
    contacts: any[];
    avatarUrl: ImageObjectTypes;
    phoneNumber?: string;
    isExpert: boolean;
    isGoogleLoggedIn?: boolean;
    isAppleLoggedIn?: boolean;
    isInfluencer: boolean;
    isLeader?: boolean;
    isStaff: boolean;
    updatedAt: DateType;
    createdAt: DateType;

    constructor(data: ObjectType) {
        this.id = data.id;
        this.nickname = data.nickname;
        this.email = data.email;
        this.contacts = data.contacts || []; 
        this.isStaff = data.is_staff;
        this.isInfluencer = data.is_influencer;
        this.isLeader = data.is_leader;
        this.isExpert = data.is_expert;
        this.avatarUrl = data.avatar;
        this.phoneNumber = data.phone_number;
        this.isGoogleLoggedIn = data.is_google_logged_in;
        this.isAppleLoggedIn = data.is_apple_logged_in;
        this.updatedAt = data.updated_at;
        this.createdAt = data.created_at;
    }

    getUser(): UserModel {
        return this;
    }
}

export default UserModel;
