import CryptoJS from 'crypto-js';

export function encrypt(text: string, salt: string | undefined = process.env.SECRET_KEY): string {
    const cipher_text = CryptoJS.AES.encrypt(text, salt ?? '').toString();
    return cipher_text;
}

export function decrypt(cipher_text: string, salt: string | undefined = process.env.SECRET_KEY): string {
    const bytes = CryptoJS.AES.decrypt(cipher_text, salt ?? '');
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
}
