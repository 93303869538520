export const MAX_SCREEN_WIDTH: number = 768;
export const MAX_HEIGHT_IMG: number = 468;
export const MAX_WIDTH_IMG: number = 375;
export const MAX_WIDTH_POPULAR_POST: number = 316;
export const MIN_HEIGHT_IMG: number = 210;
export const MIN_WIDTH_IMG: number = 375;
export const MAX_SCREEN_TABLET: number = 751;
export const MIN_SCREEN_TABLET: number = 750;
export const HEIGHT_FIXED_BANNER_TABLET: number = 370;
export const MIN_WIDTH: number = 360;
export const DEFAULT_DATA_GRID_PAGE_SIZE: number = 10;
